import './DownloadAppsCta.scss';
import JbLogo from './jb-logo.svg';
import VscLogo from './vsc-logo.svg';

export const DownloadAppsCta = () => {
  return (
    <div className="DownloadAppsContainer">
      <span>Download extension</span>
      <a
        href="https://marketplace.visualstudio.com/items?itemName=ZencoderAI.zencoder"
        target="_blank"
        rel="noreferrer"
      >
        <img src={VscLogo} alt="vsc-logo" width="32px" height="32px" />
        <span>VSCode</span>
      </a>
      <a
        href="https://plugins.jetbrains.com/plugin/24782-zencoder-your-mindful-ai-coding-agent"
        target="_blank"
        rel="noreferrer"
      >
        <img src={JbLogo} alt="jb-logo" width="32px" height="32px" />
        <span>JetBrains</span>
      </a>
    </div>
  );
};
