// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f3ef;
  color: #222222;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

a {
  cursor: pointer;
  text-decoration-line: underline;
}
a:hover {
  text-decoration-line: none;
}`, "",{"version":3,"sources":["webpack://./src/index.scss","webpack://./src/_colors.scss"],"names":[],"mappings":"AAEA;EACE,SAAA;EACA,8JAAA;EAEA,mCAAA;EACA,kCAAA;EACA,yBCRiB;EDSjB,cCRW;ADMb;;AAKA;EACE,+EAAA;AAFF;;AAKA;EACE,eAAA;EACA,+BAAA;AAFF;AAIE;EACE,0BAAA;AAFJ","sourcesContent":["@import 'colors';\n\nbody {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',\n    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: $background-color;\n  color: $color-main;\n}\n\ncode {\n  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;\n}\n\na {\n  cursor: pointer;\n  text-decoration-line: underline;\n\n  &:hover {\n    text-decoration-line: none;\n  }\n}\n","$background-color: #f2f3ef;\n$color-main: #222222;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
