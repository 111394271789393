import { useCallback, useContext } from 'react';
import { useApiClient } from './useApiClient/useApiClient';
import { useAuthUser } from '@frontegg/react';
import { useNavigate } from 'react-router-dom';
import { RootContext } from '../components/PortalLayout/PortalLayout';

type BillingInfoResponse = {
  url: string;
};

export const useNavigateToPayment = () => {
  const apiClient = useApiClient();
  const user = useAuthUser();
  const navigate = useNavigate();
  const { paymentMethod } = useContext(RootContext);

  const navigateToCheckout = useCallback(
    () => navigate(`/portal/checkout`),
    [navigate]
  );

  const navigateToBillingPortal = useCallback(async () => {
    const response = await apiClient.get<BillingInfoResponse>(
      '/api/stripe/create_billing_info_session',
      {
        email: user.email,
      }
    );

    if (!response?.url) {
      throw new Error('Failed to create billing info session');
    }

    window.location.replace(response.url);
  }, [apiClient, user.email]);

  return !!paymentMethod ? navigateToBillingPortal : navigateToCheckout;
};
