import React from 'react';
import {
  Card,
  CardTitle,
  CardDescription,
  Price,
  PlanInfo,
  CancelPlanToDowngradeNote,
} from './styled';
import { Button } from '../../components/common/Button';
import { PlanProps } from './types';
import styled from 'styled-components';

type PlanCardProps = {
  onButtonClick: (openContactForm: boolean) => void;
} & PlanProps;

const SeatsAllowance = styled.div`
  color: #555554;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const PriceSubtitle = styled.span`
  color: #555554;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  margin-bottom: 20px;
`;

const ActiveBadge = styled.div`
  width: 100%;
  padding: 10px 30px;

  color: #f24a07;
  font-family: 'Bricolage Grotesque';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.32px;
  box-sizing: border-box;
  text-align: center;
`;

const PricingCard: React.FC<PlanCardProps> = ({
  plan,
  isActive,
  priceText,
  buttonText,
  buttonProps,
  isUpgradeAllowed,
  openContactForm,
  seatsAllowance,
  onButtonClick,
}) => {
  return (
    <Card $isActive={isActive}>
      <PlanInfo>
        <CardTitle $isActive={isActive}>{plan.name}</CardTitle>
        <CardDescription>{plan.description}</CardDescription>
        <SeatsAllowance>{seatsAllowance}</SeatsAllowance>
        <Price $isActive={isActive}>{priceText}</Price>
        <PriceSubtitle>{'per user/month'}</PriceSubtitle>
        {isUpgradeAllowed ? (
          <Button {...buttonProps} onClick={() => onButtonClick(openContactForm)}>
            {buttonText}
          </Button>
        ) : isActive ? (
          <ActiveBadge>Active plan</ActiveBadge>
        ) : (
          <CancelPlanToDowngradeNote>Cancel your plan to use</CancelPlanToDowngradeNote>
        )}
      </PlanInfo>
    </Card>
  );
};

export default PricingCard;
