import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import Spinner from '../Spinner';

export type ButtonProps = {
  $variant: 'primary' | 'secondary';
  $widthType?: 'full' | 'auto';
  $filled?: boolean;
  loading?: boolean;
  onClick?: () => void;
  disabled?: boolean;
};

const getColor = (variant: ButtonProps['$variant'], filled: ButtonProps['$filled']) => {
  if (filled) {
    return '#ffffff';
  } else if (variant === 'primary') {
    return '#F24A07';
  } else if (variant === 'secondary') {
    return '#222222';
  }
};

const getBackgroundColor = (variant: ButtonProps['$variant'], filled: ButtonProps['$filled']) => {
  if (!filled) {
    return 'transparent';
  } else if (variant === 'primary') {
    return '#F24A07';
  } else if (variant === 'secondary') {
    return '#222222';
  }
};

const getBorder = (variant: ButtonProps['$variant']) => {
  switch (variant) {
    case 'primary':
      return '1px solid #F24A07';
    case 'secondary':
      return '1px solid #222222';
    default:
      return '1px solid #F24A07';
  }
};

const getHoverBackgroundColor = (
  variant: ButtonProps['$variant'],
  filled: ButtonProps['$filled']
) => {
  if (filled) {
    return variant === 'primary' ? '#E53900' : '#eeeeee';
  } else {
    return variant === 'primary' ? '#F24A07' : '#222222';
  }
};

const getHoverColor = (variant: ButtonProps['$variant'], filled: ButtonProps['$filled']) => {
  if (filled) {
    return variant === 'primary' ? '#ffffff' : '#222222';
  } else {
    return variant === 'primary' ? '#ffffff' : '#ffffff';
  }
};

const getActiveBackgroundColor = (
  variant: ButtonProps['$variant'],
  filled: ButtonProps['$filled']
) => {
  if (filled) {
    return variant === 'primary' ? '#a42a01' : '#eeeeee';
  } else {
    return variant === 'primary' ? '#a42a01' : '#525252';
  }
};

const getWidthType = (variant: ButtonProps['$widthType']) => {
  switch (variant) {
    case 'full':
      return '100%';
    case 'auto':
      return 'auto';
    default:
      return '100%';
  }
};

const ButtonComponent = styled.button<Omit<ButtonProps, 'loading'>>`
  display: flex;
  justify-content: center;
  padding: 10px 30px;
  white-space: nowrap;
  border-radius: 22px;
  width: ${({ $widthType: widthType }) => getWidthType(widthType)};
  border: ${({ $variant: variant }) => getBorder(variant)};
  color: ${({ $variant: variant, $filled: filled }) => getColor(variant, filled)};
  font-size: 16px;
  font-weight: 500;
  background-color: ${({ $variant: variant, $filled: filled }) =>
    getBackgroundColor(variant, filled)};
  transition: all 0.3s ease;
  cursor: pointer;
  font-family: 'Bricolage Grotesque';

  &:hover {
    background-color: ${({ $variant: variant, $filled: filled }) =>
      getHoverBackgroundColor(variant, filled)};
    color: ${({ $variant: variant, $filled: filled }) => getHoverColor(variant, filled)};
    border-color: ${({ $variant: variant, $filled: filled }) =>
      getHoverBackgroundColor(variant, filled)};
  }

  &:active {
    background-color: ${({ $variant: variant, $filled: filled }) =>
      getActiveBackgroundColor(variant, filled)};
    border-color: ${({ $variant: variant, $filled: filled }) =>
      getActiveBackgroundColor(variant, filled)};
    color: ${({ $variant: variant, $filled: filled }) => getHoverColor(variant, filled)};
  }

  &:disabled {
    border: ${({ $variant: variant }) => getBorder(variant)};
    color: ${({ $variant: variant, $filled: filled }) => getColor(variant, filled)};
    background-color: ${({ $variant: variant, $filled: filled }) =>
      getBackgroundColor(variant, filled)};
    cursor: auto;
  }
`;

export const Button = ({ loading, ...rest }: PropsWithChildren<ButtonProps>) => {
  return (
    <ButtonComponent {...rest} disabled={loading}>
      {loading ? <Spinner size={'sm'} /> : rest.children}
    </ButtonComponent>
  );
};
