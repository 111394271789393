import { useEffect } from 'react';
import { LOCAL_STORAGE_KEYS } from '../constants';
import { useApiClient } from './useApiClient/useApiClient';
import { useAuth } from '@frontegg/react';

export const useAttachUtm = () => {
  const apiClient = useApiClient();
  const { isAuthenticated } = useAuth();
  const utmCode = localStorage.getItem(LOCAL_STORAGE_KEYS.utmCode);

  useEffect(() => {
    const attachUtm = async () => {
      await apiClient.post(
        '/api/attach_utm',
        {
          utm: utmCode,
        },
        {
          keepalive: true,
        }
      );
    };

    if (isAuthenticated && utmCode) {
      localStorage.removeItem(LOCAL_STORAGE_KEYS.utmCode);
      attachUtm();
    }
  }, [apiClient, utmCode, isAuthenticated]);

  return null;
};
