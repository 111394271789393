// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LogoutButton {
  background-color: #222222;
  outline: 0;
  border: 0 currentcolor;
  margin: 0;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  border-radius: 16px;
  box-sizing: border-box;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
}
.LogoutButton img {
  margin-left: 4px;
}
.LogoutButton:hover {
  background-color: #444444;
}`, "",{"version":3,"sources":["webpack://./src/components/LogoutButton/LogoutButton.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,UAAA;EACA,sBAAA;EACA,SAAA;EACA,eAAA;EACA,yBAAA;UAAA,iBAAA;EACA,sBAAA;EACA,gBAAA;EACA,qBAAA;EACA,mBAAA;EACA,sBAAA;EACA,WAAA;EACA,YAAA;EACA,oBAAA;EAEA,mBAAA;EAEA,uBAAA;EACA,kBAAA;AACF;AACE;EACE,gBAAA;AACJ;AAEE;EACE,yBAAA;AAAJ","sourcesContent":[".LogoutButton {\n  background-color: #222222;\n  outline: 0;\n  border: 0 currentcolor;\n  margin: 0;\n  cursor: pointer;\n  user-select: none;\n  vertical-align: middle;\n  appearance: none;\n  text-decoration: none;\n  border-radius: 16px;\n  box-sizing: border-box;\n  width: 32px;\n  height: 32px;\n  display: inline-flex;\n  -webkit-box-align: center;\n  align-items: center;\n  -webkit-box-pack: center;\n  justify-content: center;\n  position: relative;\n\n  & img {\n    margin-left: 4px;\n  }\n\n  &:hover {\n    background-color: #444444;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
