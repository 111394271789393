import styled from 'styled-components';
import { CardProps } from './types';
import { Title } from '../../components/common/Title';

export const PageContainer = styled.div`
  width: 100%;
  margin: 0 auto 32px;
  border-radius: 12px;
  background: #fff;
`;

export const BackButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 48px;
  width: 308px;
`;

export const BackButton = styled.button`
  height: 40px;
  width: 40px;
  background-color: #e0e1e9;
  border-radius: 1000px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
`;

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 2rem;
`;

export const Card = styled.div<CardProps>`
  display: flex;
  background-color: ${(props) => (props.$isActive ? '#F9FAF8' : '#f2f3ef')};
  border-radius: 16px;
  border: ${(props) => (props.$isActive ? '1px solid #F2F3EF' : '1px solid #B1B1AE')};
  height: 332px;
`;

export const PlanInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px;
  flex: 1;
`;

export const CardTitle = styled.h2<CardProps>`
  font-size: 32px;
  font-weight: 600;
  line-height: 41.6px;
  text-align: left;
  margin-bottom: 4px;
  opacity: ${(props) => (props.$isActive ? '0.5' : '1')};
  color: ${(props) => (props.$isActive ? '#000' : '#000')};
  margin-top: 0;
  font-family: 'Bricolage Grotesque';
`;

export const CardDescription = styled.p`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 27px;
  text-align: left;
  color: #555554;
  margin-bottom: 8px;
  margin-top: 0;
  max-width: 210px;
`;

export const Price = styled.div<CardProps>`
  font-family: 'Bricolage Grotesque';
  font-size: 48px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%; /* 57.6px */

  margin: 0;
  opacity: ${(props) => (props.$isActive ? '0.5' : '1')};
  color: ${(props) => (props.$isActive ? '#000' : '#000')};
  display: flex;
  align-items: end;
  justify-content: flex-start;
  margin-top: auto;
`;

export const CancelPlanToDowngradeNote = styled.div`
  align-self: center;
  padding: 10px;
  color: #555554;
  font-size: 16px;
  white-space: nowrap;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  padding: 40px 32px 0;
`;

export const PlansRow = styled.div`
  display: grid;
  gap: 8px;
  width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
`;

export const ChangePlanTitle = styled(Title)`
  font-weight: 600;
  margin-right: 130px;
  white-space: break-spaces;
`;

export const FeaturesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 22px;
`;

export const FeatureSectionTitle = styled.span`
  width: 100%;
  padding: 20px 32px;

  color: #222;
  font-family: Inter;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
`;

export const FeatureRowWrapper = styled.div`
  display: grid;
  grid-template-columns: 300px 1fr 1fr 1fr;
  align-items: center;
  gap: 8px;

  padding: 16px 40px;

  overflow: hidden;
  color: #000;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

export const FeatureCheckWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
