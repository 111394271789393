import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useApiClient } from '../../hooks/useApiClient/useApiClient';
import Stripe from 'stripe';

const ManageSubscriptionLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
  cursor: pointer;

  color: var(--Color-Neutral-black, #000);

  font-feature-settings:
    'liga' off,
    'clig' off;
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;

  &:active,
  &:visited {
    text-decoration: none;
    color: inherit;
  }
`;

const useHasStripeAccount = () => {
  const [hasStripeAccount, setHasStripeAccount] = useState(false);

  const apiClient = useApiClient();

  useEffect(() => {
    const getStripeCustomer = async () => {
      const response = await apiClient.get<Stripe.Customer>('/api/stripe/me');
      setHasStripeAccount(Boolean(response));
    };

    getStripeCustomer();
  }, [apiClient]);

  return hasStripeAccount;
};

const ManageSubscriptionButton: React.FC = () => {
  const hasStripeAccount = useHasStripeAccount();

  if (!hasStripeAccount) {
    return null;
  }

  return (
    <ManageSubscriptionLink
      to="/portal/manage-subscription"
      reloadDocument={true}
      className="ManageSubscriptionButton"
    >
      Manage Subscription
    </ManageSubscriptionLink>
  );
};

export default ManageSubscriptionButton;
