import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { Link, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import { Text } from '../components/common/Text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 36px;
  color: #ff7a06;
  margin-bottom: 10px;
`;

const Subtitle = styled.h2`
  font-size: 24px;
  color: black;
  margin-bottom: 20px;
`;

const ErrorMessage = styled.div`
  color: black;
  font-size: 20px;
  margin-bottom: 20px;
  text-align: center;
`;

const BackLink = styled(Link)`
  font-size: 18px;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
`;

export const WrongRedirectUrl = () => {
  const [searchParams] = useSearchParams();

  const unsafeUrl = searchParams.get('unsafeUrl');

  useEffect(() => {
    Sentry.captureException(`Unsafe redirect url ${unsafeUrl}`);
  }, [unsafeUrl]);

  return (
    <Container>
      <Title>WARNING!</Title>
      <Subtitle>Unsafe Redirect URL {unsafeUrl}</Subtitle>
      <ErrorMessage>
        Make sure you're accessing login page from our official website{' '}
        <a href="https://zencoder.ai">https://zencoder.ai</a>
        <br />
        <br />
        or from our extensions for
        <Text
          style={{
            textAlign: 'center',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '20px',
            marginTop: '8px',
          }}
        >
          <a href="https://plugins.jetbrains.com/plugin/24782-zencoder-your-mindful-ai-coding-agent">
            JetBrains
          </a>
          &nbsp;|&nbsp;
          <a href="https://marketplace.visualstudio.com/items?itemName=ZencoderAI.zencoder">
            VSCode
          </a>
        </Text>
      </ErrorMessage>
      <br />
      <BackLink to="/">Back to Login</BackLink>
    </Container>
  );
};
