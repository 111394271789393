import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useAuthUser } from '@frontegg/react';
import { ReactComponent as LogoImg } from '../../logo.svg';

const HeaderContainer = styled.div`
  font-family: Inter;
  background-color: #f2f3ef;
  padding: 32px 0;
  margin: 0 auto;
  max-width: 1200px;
`;

const ContentSection = styled.div`
  align-items: center;
  display: flex;
  gap: 20px;
  justify-content: space-between;
`;

const LogoSection = styled.div`
  align-items: center;
  display: flex;
  gap: 19px;

  color: #2b2e38;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;
`;

const UserInfoSection = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

const UserFullname = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #2b2e38;
`;

const UserEmail = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15px;
  color: #2b2e38;
`;

const Logo = styled(LogoImg)`
  width: 184px;
  height: 34px;
`;

const AdminPanelLink = styled(Link)`
  color: #2b2e38;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  text-align: left;

  text-decoration: none;
`;

const Header = () => {
  const user = useAuthUser();

  return (
    <HeaderContainer>
      <ContentSection>
        <LogoSection>
          <Logo />
          <AdminPanelLink to={'/'}>↗︎ User Admin Panel</AdminPanelLink>
        </LogoSection>
        {user && (
          <UserInfoSection>
            <div>
              <UserFullname>{user.name}</UserFullname>
              <UserEmail>{user.email}</UserEmail>
            </div>
            {user.profilePictureUrl && (
              <img
                src={user.profilePictureUrl}
                alt="Profile"
                width={36}
                style={{ borderRadius: '50%' }}
              />
            )}
          </UserInfoSection>
        )}
      </ContentSection>
    </HeaderContainer>
  );
};

export default Header;
