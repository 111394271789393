import { ButtonHTMLAttributes } from 'react';

import './Button.scss';

export type ButtonType = 'primary' | 'default';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: ButtonType;
}

export function Button({ buttonType = 'default', className, children, ...rest }: Props) {
  const classNames = ['Button'];
  buttonType === 'primary' && classNames.push('primary');
  className && classNames.push(className);

  return (
    <button className={classNames.join(' ')} {...rest}>
      {children}
    </button>
  );
}
