import { AdminPortal, useAuth } from '@frontegg/react';
import { useEffect } from 'react';
import {
  AdminPortalThemeOptions,
  PageThemeOptions,
  ProfilePageThemeOptions,
} from '@frontegg/types/ThemeOptions/AdminPortalThemeOptions';
import { LogoutButton } from '../LogoutButton/LogoutButton';
import { LoaderPage } from '../LoaderPage/LoaderPage';
import { CustomButtonsContainer } from './CustomButtonsContainer';
import ManageSubscriptionButton from './ManageSubscriptionButton';
import { DownloadAppsCta } from './DownloadAppsCta/DownloadAppsCta';

const modalOverlayFix: PageThemeOptions = {
  components: {
    MuiDialog: {
      styleOverrides: {
        root: {
          zIndex: '1350 !important',
        },
      },
    },
  },
};

const profilePageThemeOptions: ProfilePageThemeOptions = {
  ...modalOverlayFix,
  fieldsProperties: {
    address: {
      appearance: 'hidden',
    },
    jobTitle: {
      appearance: 'hidden',
    },
    phoneNumber: {
      appearance: 'hidden',
    },
  },
};

export const adminPortalThemeOptions: AdminPortalThemeOptions = {
  layout: {
    fullScreenMode: true,
  },
  pages: {
    content: {
      background: '#F2F3EF',
    },
    header: {
      background: '#F2F3EF',
    },
    profile: profilePageThemeOptions,
    privacy: modalOverlayFix,
    personalApiTokens: modalOverlayFix,
    account: profilePageThemeOptions,
    users: modalOverlayFix,
    security: modalOverlayFix,
    singleSignOn: modalOverlayFix,
    audits: modalOverlayFix,
    webhooks: modalOverlayFix,
    apiTokens: modalOverlayFix,
    roles: modalOverlayFix,
    subscriptions: modalOverlayFix,
    application: modalOverlayFix,
  },
  navigation: {
    groupTitleColor: '#fff',
    headerColor: '#fff',
    subHeaderColor: '#fff',
    background: '#222222',
    default: {
      color: '#FEFEFD',
    },
    hover: {
      background: 'rgba(255,255,255,0.08)',
    },
    selected: {
      color: '#F24A07 !important',
      iconColor: '#F24A07 !important',
      background: 'rgba(255,255,255,0.08)',
      borderBottomColor: 'transparent',
    },
  },
};

export function AdminPanelView() {
  const { user, isAuthenticated } = useAuth();

  useEffect(() => {
    AdminPortal.show();
    hideAdminPortalCloseButton();
  }, []);

  if (!user || !isAuthenticated) {
    return <LoaderPage />;
  }

  return (
    <>
      <CustomButtonsContainer>
        <ManageSubscriptionButton />
        <LogoutButton />
      </CustomButtonsContainer>
      <DownloadAppsCta />
    </>
  );
}

function hideAdminPortalCloseButton() {
  const root = document.getElementById('frontegg-admin-portal-container-default');
  if (!root?.shadowRoot?.adoptedStyleSheets) {
    return;
  }
  const sheet = new CSSStyleSheet();
  sheet.replaceSync(`button:has([data-test-id="X-btn"]) { display: none }`);
  root.shadowRoot.adoptedStyleSheets.push(sheet);
}
