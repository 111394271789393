import { usePlansWithProps } from '../../hooks/useStripePlans';
import {
  BackButton,
  BackButtonContainer,
  ChangePlanTitle,
  FeatureCheckWrapper,
  FeatureRowWrapper,
  FeatureSectionTitle,
  FeaturesListContainer,
  Header,
  PageContainer,
  PlansRow,
} from './styled';
import ArrowLeft from './icons/ArrowLeft.svg';
import { LoaderPage } from '../../components/LoaderPage/LoaderPage';
import PricingCard from './PricingCard';
import { plansFeatureList } from './plansFeatureList';
import { FeatureValue, PlanFeature } from './types';
import { Divider } from '../../components/common/Divider';
import { ReactComponent as CheckMark } from './icons/checkMark.svg';
import { ReactComponent as Cross } from './icons/cross.svg';
import { Fragment, useState } from 'react';
import { useNavigateToPayment } from '../../hooks/useNavigateToPayment';
import UpgradeToEnterpriseForm from './UpgradeToEnterpriseForm';
import { useNavigate } from 'react-router-dom';

const FeatureCheck = ({ value }: { value: FeatureValue }) => {
  const content = typeof value === 'string' ? value : value ? <CheckMark /> : <Cross />;

  return <FeatureCheckWrapper>{content}</FeatureCheckWrapper>;
};

const FeaturesSection = ({ title, features }: { title: string; features: PlanFeature[] }) => {
  return (
    <>
      <FeatureSectionTitle key={`${title}-section`}>{title}</FeatureSectionTitle>
      {features.map((feature, idx) => (
        <Fragment key={feature.name}>
          <FeatureRow {...feature} />
          {idx !== features.length - 1 && <Divider />}
        </Fragment>
      ))}
    </>
  );
};

const FeatureRow = ({ name, free, business, enterprise }: PlanFeature) => {
  return (
    <FeatureRowWrapper>
      <span>{name}</span>
      <FeatureCheck value={free} />
      <FeatureCheck value={business} />
      <FeatureCheck value={enterprise} />
    </FeatureRowWrapper>
  );
};

const FeaturesList = () => {
  return (
    <FeaturesListContainer>
      <FeaturesSection title="Core Features" features={plansFeatureList.core} />
      <FeaturesSection title="Advanced AI Features" features={plansFeatureList.advanced} />
      <FeaturesSection title="Team & Enterprise Features" features={plansFeatureList.enterprise} />
    </FeaturesListContainer>
  );
};

export const PricingPage = () => {
  const { plans, loading } = usePlansWithProps();
  const [isContactFormOpen, setIsContactFormOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const navigateToBillingInfo = useNavigateToPayment();

  const handlePlanSelection = async (openContactForm: boolean = false) => {
    if (openContactForm) {
      setIsContactFormOpen(true);
    } else {
      navigateToBillingInfo();
    }
  };

  if (loading) return <LoaderPage />;

  return (
    <PageContainer>
      <Header>
        <BackButtonContainer>
          <BackButton onClick={() => navigate(-1)}>
            <img src={ArrowLeft} alt="Back" />
          </BackButton>
          <ChangePlanTitle $size={'large'}>Change plan</ChangePlanTitle>
        </BackButtonContainer>
        <PlansRow>
          {plans.map((plan) => (
            <PricingCard key={plan.plan.id} {...plan} onButtonClick={handlePlanSelection} />
          ))}
        </PlansRow>
      </Header>
      <FeaturesList />
      <UpgradeToEnterpriseForm
        isOpen={isContactFormOpen}
        onClose={() => setIsContactFormOpen(false)}
      />
    </PageContainer>
  );
};
