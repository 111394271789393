import { useContext, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import Modal from '../../components/Modal/Modal';
import { Title } from '../../components/common/Title';
import { RootContext } from '../../components/PortalLayout/PortalLayout';

type UpgradeToEnterpriseFormProps = {
  isOpen: boolean;
  onClose: () => void;
};

// Styled-components for layout
const FormContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  align-items: flex-start;
  padding: 40px 48px;
  border-radius: 8px;
  background-color: #f8f9fa;
  margin: 0 auto;
`;

const PlanInfo = styled.div`
  flex: 1;
`;

const Subtitle = styled(Title)`
  margin-top: 30px;
  margin-bottom: 20px;
  font-family: Inter;

  line-height: 150%; /* 27px */
`;

const List = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0 0 20px 0;

  li {
    margin-bottom: 10px;
    display: flex;
    align-items: center;

    color: #222;

    /* Text/Regular */
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%; /* 24px */

    &::before {
      content: '✔️';
      margin-right: 12px;
    }
  }
`;

const HubspotFormContainer = styled.div`
  flex: 1;
  min-width: 320px;
  align-self: center;
  margin-top: 90px;
`;

const UpgradeToEnterpriseForm = ({ isOpen, onClose }: UpgradeToEnterpriseFormProps) => {
  const { plans = [] } = useContext(RootContext);

  const features = useMemo(() => {
    return (
      plans
        .find((plan) => plan.metadata.alias === 'enterprise')
        ?.features.map((feature) => feature.name) || []
    );
  }, [plans]);

  useEffect(() => {
    // @ts-ignore
    if (window.hbspt) {
      // @ts-ignore
      window.hbspt.forms.create({
        portalId: '46014728',
        formId: 'd20d2d84-81ce-404a-96ee-2b88301b111c',
        target: '#contact-us',
      });
    }
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <FormContainer>
        <PlanInfo>
          <Title $size={'medium'}>Upgrade to Enterprise</Title>
          <Subtitle $size={'xs'}>Includes Business plan, plus:</Subtitle>
          <List>
            {features.map((feature, index) => (
              <li key={index}>{feature}</li>
            ))}
          </List>
        </PlanInfo>
        <HubspotFormContainer id={'contact-us'}></HubspotFormContainer>
      </FormContainer>
    </Modal>
  );
};

export default UpgradeToEnterpriseForm;
