import styled, { keyframes } from 'styled-components';
import { ReactComponent as LogoIcon } from '../spinning-logo.svg';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const SpinnerWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
`;

type SizeType = 'sm' | 'lg';

const SpinningLogo = styled(LogoIcon)<{ $size?: SizeType }>`
  animation: ${spin} 2s linear infinite;
  width: ${({ $size }) => ($size === 'sm' ? `18px` : '50px')};
  height: ${({ $size }) => ($size === 'sm' ? `18px` : '50px')};
`;

const Spinner = ({ size = 'lg', className }: { size?: SizeType; className?: string }) => {
  if (size === 'sm') {
    return <SpinningLogo $size={size} />;
  }

  return (
    <SpinnerWrapper className={className}>
      <SpinningLogo />
    </SpinnerWrapper>
  );
};

export default Spinner;
