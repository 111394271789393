// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LoaderPage .Loader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.LoaderPage .Loader img {
  width: 40px;
  height: 40px;
  margin: -20px 0 0 -20px;
  animation: spin 4s linear infinite;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/components/LoaderPage/LoaderPage.scss"],"names":[],"mappings":"AACE;EACE,eAAA;EACA,QAAA;EACA,SAAA;EACA,gCAAA;AAAJ;AAEI;EACE,WAAA;EACA,YAAA;EACA,uBAAA;EACA,kCAAA;AAAN;;AAKA;EACE;IACE,yBAAA;EAFF;AACF","sourcesContent":[".LoaderPage {\n  .Loader {\n    position: fixed;\n    top: 50%;\n    left: 50%;\n    transform: translate(-50%, -50%);\n\n    img {\n      width: 40px;\n      height: 40px;\n      margin: -20px 0 0 -20px;\n      animation: spin 4s linear infinite;\n    }\n  }\n}\n\n@keyframes spin {\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
