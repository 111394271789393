import { useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { useApiClient } from '../../hooks/useApiClient/useApiClient';
import { getConfig } from '../../config/getConfig';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin-top: 40px;
`;

const useFetchClientSecret = () => {
  const apiClient = useApiClient();
  return useCallback(async () => {
    const result = await apiClient.get<{ clientSecret: string }>(
      '/api/stripe/create_checkout_session'
    );

    if (!result) throw new Error('No client secret found');

    return result?.clientSecret;
  }, [apiClient]);
};

const { stripePubKey } = getConfig();
const stripePromise = loadStripe(stripePubKey);

export const CheckoutPage = () => {
  const fetchClientSecret = useFetchClientSecret();
  const options = { fetchClientSecret };

  return (
    <Wrapper id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </Wrapper>
  );
};
