import { useAuth } from '@frontegg/react';
import { getConfig } from '../../config/getConfig';
import { useMemo } from 'react';

const config = getConfig();

export const useApiClient = () => {
  const { user } = useAuth();

  return useMemo(
    () => ({
      get: async <T>(path: string, params?: Record<string, string>): Promise<T | null> => {
        if (!user) throw Error('User is not authenticated');

        const query = new URLSearchParams(params).toString();

        const response = await fetch(`${config.apiBaseUrl}${path}${query ? `?${query}` : ''}`, {
          headers: {
            Authorization: `Bearer ${user.accessToken}`,
          },
        });

        if (response.status !== 200) {
          return null;
        }

        return response.json();
      },
      post: async <T>(
        path: string,
        data: Record<string, unknown>,
        options: RequestInit = {}
      ): Promise<T> => {
        if (!user) throw Error('User is not authenticated');

        const response = await fetch(`${config.apiBaseUrl}${path}`, {
          method: 'POST',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${user.accessToken}`,
          },
          ...options,
        });
        return response.json();
      },
    }),
    [user]
  );
};
