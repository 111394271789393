import { useEffect, useState } from 'react';
import { useApiClient } from './useApiClient/useApiClient';

export type SessionStatusResponse = {
  status: string;
  email: string;
};

export const usePaymentStatus = (sessionId: string | null) => {
  const [loading, setLoading] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState<SessionStatusResponse | null>(null);

  const apiClient = useApiClient();

  useEffect(() => {
    if (!sessionId) {
      throw new Error('sessionId is required');
    }

    const getPaymentStatus = async () => {
      setLoading(true);
      const response = await apiClient.get<SessionStatusResponse>(
        '/api/stripe/get_session_status',
        {
          session_id: sessionId,
        }
      );

      setPaymentStatus(response);
      setTimeout(() => setLoading(false), 1000);
    };

    getPaymentStatus();
  }, [sessionId, apiClient]);

  return {
    loading,
    status: paymentStatus?.status,
  };
};
