import styled from 'styled-components';

type Props = {
  $size?: 'xs' | 'small' | 'medium' | 'large';
};

export const Title = styled.h1<Props>`
  font-size: ${({ $size = 'large' }) =>
    $size === 'xs' ? '18px' : $size === 'small' ? '24px' : $size === 'medium' ? '32px' : '48px'};
  font-weight: ${({ $size = 'large' }) =>
    $size === 'large' ? 700 : $size === 'medium' ? 600 : 500};
  line-height: 58px;
  color: #222222;
  margin: 0;
  white-space: nowrap;
  font-family: 'Bricolage Grotesque', Inter;
`;
