import { useCallback, useState } from 'react';
import './TermsOfService.scss';
import { useAuth } from '@frontegg/react';
import { LoaderPage } from '../LoaderPage/LoaderPage';

export default function TermsOfService() {
  const { user } = useAuth();
  const doLogout = () => {
    window.location.replace(`/account/logout`);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleAccept = useCallback(() => {
    const url = '/accept_terms';
    setIsLoading(true);
    fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${user!.accessToken}`,
      },
    }).then((res) => {
      if (res.status === 200) {
        setIsLoading(false);
        window.location.reload();
      } else {
        setIsLoading(false);
      }
    });
  }, [user]);

  if (!user) {
    window.location.replace(`/account/login`);
    return <LoaderPage></LoaderPage>;
  }

  return (
    <div className={'TermsOfServiceBackground'}>
      <div className={'TermsOfServiceModalWrapper'}>
        <div className={'TermsOfServiceModal'}>
          <div className={'TermsOfServiceContent'}>
            <h1 className={'TermsOfServiceHeader'}>
              Terms of Service and <br /> Privacy Policy
            </h1>
            <p className={'TermsOfServiceBody'}>
              Please review our{' '}
              <a
                href={'https://zencoder.ai/terms-of-service'}
                target={'_blank'}
                rel={'noreferrer'}
                className={'TermsOfServiceLink'}
              >
                Terms of Service
              </a>{' '}
              and{' '}
              <a
                href={'https://zencoder.ai/privacy-policy'}
                target={'_blank'}
                rel={'noreferrer'}
                className={'TermsOfServiceLink'}
              >
                Privacy
                <br />
                Policy
              </a>
              . By clicking “Accept”, you agree to our terms
              <br />
              and policies.
            </p>
          </div>
          <div className={'TermsOfServiceActions'}>
            <button
              className={'TermsOfServiceAcceptButton'}
              onClick={handleAccept}
              disabled={isLoading}
            >
              Accept
            </button>
            <button onClick={doLogout} className={'TermsOfServiceDeclineButton'}>
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
