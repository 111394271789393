import { IConfig } from './config.interface';

const devConfig: IConfig = {
  frontegg: {
    apiUrl: 'https://dev.fe.zencoder.ai',
    defaultAppId: '565f75f5-8ec4-484a-b81c-6b518a6b4405',
  },
  apiBaseUrl: 'http://localhost:8080',
  stripePubKey:
    'pk_test_51PAvvvKID1k3Z3c1MSzN0EHAl2QjMJikcr3Y95sBgHfqFyt34MYEs07uPtg08nWvFpgaJaCy8o3CDpj4OLgMU6tP00qq1mBY2K',
};

export default devConfig;
