import { useEffect } from 'react';
import { LoaderPage } from '../components/LoaderPage/LoaderPage';
import { useApiClient } from '../hooks/useApiClient/useApiClient';

export const WelcomePage = () => {
  const apiClient = useApiClient();

  useEffect(() => {
    apiClient.post('/api/set_welcome_shown', {}, { keepalive: true });
    window.location.replace('https://zencoder.ai/download');
  }, [apiClient]);

  return <LoaderPage />;
};
