import { useState } from 'react';
import './LogoutButton.scss';
import Modal, { ModalActions, ModalContent, ModalHeader } from '../Modal/Modal';
import { Button } from '../Button/Button';

import logoutIcon from '../../logout.svg';
import { ContextHolder } from '@frontegg/react';

export function LogoutButton() {
  const [open, setOpen] = useState(false);

  const handleLogoutClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  const doLogout = () => {
    const baseUrl = ContextHolder.default().getContext().baseUrl;
    window.location.href = `${baseUrl}/oauth/logout?post_logout_redirect_uri=${window.location}`;
  };

  return (
    <>
      <button className={'LogoutButton'} onClick={handleLogoutClick}>
        <img src={logoutIcon} alt={'Log out'} />
      </button>
      <Modal
        isOpen={open}
        closeOnClickOutside={true}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ModalHeader>Log out</ModalHeader>
        <ModalContent>Are you sure you want to log out?</ModalContent>
        <ModalActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button buttonType={'primary'} onClick={doLogout} autoFocus>
            Log out
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
}
