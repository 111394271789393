import React, { PropsWithChildren } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

type ModalProps = PropsWithChildren & {
  isOpen: boolean;
  onClose: () => void;
  closeOnClickOutside?: boolean;
};

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 1350;
`;

const ModalContainer = styled.div`
  padding: 0;
  background-color: #f8f9fa;
  border-radius: 8px;
  position: relative;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;

export const ModalHeader = styled.div`
  padding: 20px 24px;

  border-bottom: 1px solid #eaeaea;
  font-size: 20px;
`;

export const ModalContent = styled.div`
  padding: 20px 24px;
  min-width: 400px;
`;

export const ModalActions = styled.div`
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #eaeaea;
  padding: 14px 16px;
  gap: 8px;
`;

const Modal = ({ isOpen, onClose, closeOnClickOutside = true, children }: ModalProps) => {
  if (!isOpen) return null;

  const handleBackdropClick = (e: React.MouseEvent) => {
    if (closeOnClickOutside && e.target === e.currentTarget) {
      onClose();
    }
  };

  const modalRoot = document.getElementById('modal-root');

  return ReactDOM.createPortal(
    <Backdrop onClick={handleBackdropClick}>
      <ModalContainer>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        {children}
      </ModalContainer>
    </Backdrop>,
    modalRoot!
  );
};

export default Modal;
