import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { Label } from './Label';
import { Text } from './Text';

type InfoItemProps = PropsWithChildren & {
  label: string;
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const InfoItem = ({ label, children }: InfoItemProps) => {
  return (
    <Wrapper>
      <Label>{label}</Label>
      <Text>{children}</Text>
    </Wrapper>
  );
};
