import { useLoginWithRedirect } from '@frontegg/react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { getConfig } from '../../config/getConfig';
import { LoaderPage } from '../LoaderPage/LoaderPage';
import { Navigate } from 'react-router-dom';
import { isSafeRedirectUrl } from '../../utils/isSafeRedirectUrl';
import { LOCAL_STORAGE_KEYS } from '../../constants';

export const LoginBoxView = () => {
  const { pathname } = useLocation();
  const loginAndRedirect = useLoginWithRedirect();

  const [searchParams] = useSearchParams();
  const redirectURL = searchParams.get('redirectTo');
  const utmParam = searchParams.get('utm');

  const navigateToHostedLogin = () => {
    const isSignUp = pathname === '/signup';
    const {
      frontegg: { apiUrl },
    } = getConfig();

    if (isSignUp) {
      if (utmParam) {
        localStorage.setItem(LOCAL_STORAGE_KEYS.utmCode, utmParam);
      }

      window.location.replace(`${apiUrl}/oauth/account/sign-up`);
    } else {
      loginAndRedirect();
    }
  };

  if (!redirectURL) {
    navigateToHostedLogin();
    return null;
  }

  if (!isSafeRedirectUrl(redirectURL)) {
    return <Navigate to={`/unsafe-redirect-url?unsafeUrl=${redirectURL}`} />;
  }

  localStorage.setItem(LOCAL_STORAGE_KEYS.originUrl, window.location.href);
  navigateToHostedLogin();

  return <LoaderPage />;
};
