import styled from 'styled-components';

type TextProps = {
  $variant?: 'primary' | 'secondary' | 'error';
};

export const Text = styled.span<TextProps>`
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 4px;
  white-space: nowrap;
  color: ${(props) => {
    if (props.$variant === 'primary') {
      return '#222222';
    } else if (props.$variant === 'secondary') {
      return '#999999';
    } else if (props.$variant === 'error') {
      return '#DE1135';
    } else {
      return '#222222';
    }
  }};
`;
