import styled from 'styled-components';

export const CustomButtonsContainer = styled.div`
  display: flex;
  column-gap: 24px;
  position: absolute;
  top: 32px;
  right: 24px;
  z-index: 1320;
`;
