import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { styled } from 'styled-components';
import { usePaymentStatus } from '../hooks/usePaymentStatus';
import Spinner from '../components/Spinner';
import { Title } from '../components/common/Title';
import { Text } from '../components/common/Text';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: Inter;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 32px;
  margin-top: 150px;
`;

const Loader = styled(Spinner)`
  div& {
    margin-top: 40px;
    width: auto;
    height: auto;
  }
`;

export const Pending = () => {
  return (
    <Container>
      <Title>Payment Pending</Title>
      <Text>Payment is being processed. Please wait...</Text>
      <Loader />
    </Container>
  );
};

const Status = ({ status }: { status?: string }) => {
  const navigate = useNavigate();

  if (status === 'complete') setTimeout(() => navigate('/portal/manage-subscription'), 3000);

  return (
    <Container>
      {status === 'complete' ? (
        <>
          <Title>Congratulations! 🥳 </Title>
          <Text>Your payment was successful. You will be redirected shortly.</Text>
        </>
      ) : (
        <>
          <p>There was an issue with your payment. Please try again. </p>
          <Link to={'/portal/manage-subscription'}>Back to portal</Link>
        </>
      )}
    </Container>
  );
};

export const PaymentStatus = () => {
  const [params] = useSearchParams();

  const sessionId = params.get('session_id');
  const sessionStatus = usePaymentStatus(sessionId);

  return (
    <div>{sessionStatus.loading ? <Pending /> : <Status status={sessionStatus.status} />}</div>
  );
};
