import styled from 'styled-components';
import { SubscriptionInfo } from './SubscriptionInfo';
import { Members } from './Members';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 8px;
`;

export const ManageSubscriptionPage = () => {
  return (
    <Wrapper>
      <SubscriptionInfo />
      <Members />
    </Wrapper>
  );
};
