const usdFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const formatMoney = (price: number, cents: boolean = false) => {
  return usdFormatter.format(cents ? price / 100 : price);
};
