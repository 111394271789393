import { FeatureList } from './types';

export const plansFeatureList: FeatureList = {
  core: [
    { name: 'Unlimited Code Completion', free: true, business: true, enterprise: true },
    { name: 'Unlimited Chat Messages', free: true, business: true, enterprise: true },
    { name: 'Custom AI Instructions', free: true, business: true, enterprise: true },
    { name: 'Code Repair', free: 'Basic', business: 'Advanced', enterprise: 'Advanced' },
  ],
  advanced: [
    {
      name: 'Requests per day',
      free: 'Limited',
      business: '10x Higher Limits',
      enterprise: 'Unlimited',
    },
    { name: 'Repo Grokking', free: 'Basic', business: 'Advanced', enterprise: 'Advanced+' },
    { name: 'Code Generation and Improve', free: true, business: true, enterprise: true },
    { name: 'Unit Test Generation', free: true, business: true, enterprise: true },
    { name: 'Docstring Generation', free: true, business: true, enterprise: true },
    { name: 'Coding Agent (beta)', free: true, business: true, enterprise: true },
    { name: 'Custom Agents (beta)', free: true, business: true, enterprise: true },
  ],
  enterprise: [
    { name: 'Team Management', free: true, business: true, enterprise: true },
    { name: 'Single Sign On (SSO)', free: false, business: false, enterprise: true },
    { name: 'Audit Logs', free: false, business: false, enterprise: true },
    { name: 'Private Fine-Tuning', free: false, business: false, enterprise: true },
    { name: 'Priority Support', free: false, business: false, enterprise: true },
  ],
};
