import styled from 'styled-components';

type ContentWrapperProps = {
  $error?: boolean;
  direction?: 'column' | 'row';
};

export const ContentWrapper = styled.div<ContentWrapperProps>`
  display: flex;
  font-family: Inter;
  flex-direction: ${({ direction = 'column' }) => direction};
  width: auto;
  border-radius: 12px;
  background-color: white;

  ${({ $error: error = false }) =>
    error
      ? {
          background:
            'linear-gradient(184deg, rgba(222, 17, 53, 0.5) -9.24%, rgba(256, 255, 255, 1.00) 40.59%)',
        }
      : {
          backgroundColor: '#ffffff',
        }}
`;
