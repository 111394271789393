import { styled } from 'styled-components';

export const Heading = styled.div`
  display: flex;
  flex-direction: column;
  padding: 32px;
`;

export const TitleSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;
