import styled from 'styled-components';

type Props = {
  $variant?: 'warning' | 'regular';
};

export const Label = styled.label<Props>`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  color: #222222;
  opacity: 0.5;

  ${({ $variant = 'regular' }: Props) => ({
    color: $variant === 'warning' ? '#F24A07' : '#222222',
    opacity: 1,
  })}
`;
