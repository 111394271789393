import logo from '../../spinning-logo.svg';
import React from 'react';

import './LoaderPage.scss';

export function LoaderPage() {
  return (
    <div className={'LoaderPage'}>
      <div className={'Loader'}>
        <img src={logo} alt={'Zencoder'} />
      </div>
    </div>
  );
}
